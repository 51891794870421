<template>
  <div id="app">
    <Header title="Cash" :hideUserDropdown="!user" id="header" />
    <Loading v-if="validatingToken" />
    <Content v-else />
    <Footer />
    <LGPDModal />
    <LGPDSuporteModal v-if="user && user.tipoUsuario >= 1" />
  </div>
</template>

<script>
import axios from "axios";
import { userKey } from "@/global";
import { decodeToken } from "@/config/globalFacilities";
import { baseApiUrl, baseFrotendAuth, baseAuthApiUrl } from "@/env";
import { mapState } from "vuex";
import Header from "@/components/template/Header";
import Content from "@/components/template/Content";
import Footer from "@/components/template/Footer";
import Loading from "@/components/template/Loading";
import LGPDModal from './components/msgs/LGPDModal.vue';
import LGPDSuporteModal from './components/msgs/LGPDSuporteModal.vue';

export default {
  name: "App",
  components: { Header, Content, Footer, Loading, LGPDModal, LGPDSuporteModal },
  data: function () {
    return {
      validatingToken: true,
    };
  },
  methods: {
    async signin() {
      const query = this.$route.query;

      const json = localStorage.getItem(userKey);
      if (!json && !query.signin) {
        window.location.href = baseFrotendAuth;
        return;
      }
      if (query.signin) {
        let userToken = query.signin
        let token = userToken.split("signin")[0]
        const userAuth = decodeToken(token);
        const userData = { ...userAuth, token };
        userData.name = userData.name.split(" ")[0];
        this.$store.commit("setUser", userData);
        localStorage.setItem(userKey, JSON.stringify(userData));
        this.$router.push({ path: "/" });
      }
      return true;
    },
    async validateToken() {
      this.validatingToken = true;

      await this.signin();

      const json = localStorage.getItem(userKey);
      if (!json) {
        window.location.href = baseFrotendAuth;
        return;
      }
      const userData = JSON.parse(json);
      this.$store.commit("setUser", null);

      // Declarar rotas alternativas criadas em router.js
      // As rotas alternativas dispensam validação de usuário
      const alternates = [
        // "auth",
        "apresentacao",
        "validator",
        "request-password-reset",
        "password-reset",
      ];

      // rota primária
      if (!userData && this.$route.name === "user-unlock") {
        const res = await axios.get(
          `${baseApiUrl}/${this.$route.name}/${this.$route.params.id}/${this.$route.params.token}`
        );

        this.$toasted.global.defaultSuccess({ msg: res.data });
        window.location.href = baseFrotendAuth;
        // this.$router.push({ name: alternates[0] });
      }

      if (!userData && alternates.indexOf(this.$route.name) < 0) {
        this.$cookies.set("noUserRoute", this.$route.fullPath);
        this.validatingToken = false;
        window.location.href = baseFrotendAuth;
        // this.$router.push({ name: alternates[0] });
        return;
      } else {
        const res = await axios.post(`${baseAuthApiUrl}/validateToken`, userData);

        if (res.data) {
          this.$store.commit("setUser", userData);
        } else {
          localStorage.removeItem(userKey);
          if (alternates.indexOf(this.$route.name) < 0)
            window.location.href = baseFrotendAuth;
          // this.$router.push({ name: alternates[0] });
        }

        this.validatingToken = false;
      }
    }
  },
  created() {
    this.validateToken();
    this.$cookies.set("uLast", this.$route.fullPath);
  },
  computed: mapState(["user"]),
};
</script>

<style>
* {
  font-family: "Lato", sans-serif;
}

body {
  margin: 0;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100vh;
  display: grid;
  grid-template-rows: 60px 1fr 40px;
  grid-template-columns: 300px 1fr;
  grid-template-areas:
    "header header"
    "content content"
    "footer footer";
}
</style>